import { useEffect, useState } from 'react';
import { camelizeKeys } from 'humps';
import { useMsal } from '@azure/msal-react';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import { policies } from '../../b2c';
import { getSigInWithNegotiateMfaForAccessTokenRequest } from '../../b2c/policies';
import { useIdToken, useLoginRedirect } from '../../hooks';
import { ProfileDetails } from '../api.types';

const useGetCustomerDetails = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<ProfileDetails>();
  const [acquireTokenRedirect] = useLoginRedirect();
  const { getNegotiateMfaForceIdToken, getSigninSignupIdTokenWithMfa } =
    useIdToken();

  useEffect(() => {
    const getTokenSilent = async () => {
      if (inProgress !== InteractionStatus.None) return;

      // To acquire an access token the signin policy should be used. However, after a password reset and signin
      // only the reset password policy account will exist here. Therefore we need to authenticate against the
      // signin policy if the account does not already exist.
      let account = accounts.find(
        (account) =>
          account.idTokenClaims &&
          account.idTokenClaims['trustFrameworkPolicy'] ===
            process.env.REACT_APP_AZURE_B2C_SIGNIN_POLICY_NAME
      );

      try {
        if (!account) {
          const idTokenHint = await getSigninSignupIdTokenWithMfa();
          const authRequest = {
            ...policies.authorities.signUpSignIn,
            extraQueryParameters: { id_token_hint: idTokenHint },
          };

          try {
            await instance.ssoSilent(authRequest);
          } catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
              await instance.loginRedirect(authRequest);
              return;
            }
            throw error;
          }

          return;
        }

        const authenticationResult = await instance.acquireTokenSilent({
          account: account,
          scopes: policies.authorities.signUpSignInAcquireToken.scopes,
          authority: policies.authorities.signUpSignInAcquireToken.authority,
        });

        return authenticationResult.accessToken;
      } catch (error) {
        // https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=react#acquire-a-token-with-a-redirect
        // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2927
        // Force Mfa to receive isActiveMfaSession in Access Token in the request above
        if (error instanceof InteractionRequiredAuthError) {
          console.warn(
            `⚠️ useGetCustomerDetails ~ acquireTokenSilent failed, trying again...`,
            error
          );

          const idTokenHint = await getNegotiateMfaForceIdToken();
          await acquireTokenRedirect(
            getSigInWithNegotiateMfaForAccessTokenRequest(
              account || accounts[0],
              idTokenHint
            )
          );
          return;
        }
        console.log(
          `🧪 ❌ useGetCustomerDetails ~ acquireTokenSilent failed`,
          error
        );
        throw error;
      }
    };

    const getCustomerProfile = async () => {
      try {
        const accessToken = await getTokenSilent();
        if (!accessToken) return;

        const headers = new Headers({
          ['ocp-apim-subscription-key']:
            process.env.REACT_APP_DCR_DCR_SUBSCRIPTION_KEY || '',
          Authorization: `Bearer ${accessToken}`,
        });

        const response = await fetch(
          `${process.env.REACT_APP_DCR_CUSTOMER_URL}`,
          {
            headers,
          }
        );

        if (response?.status !== 200) {
          setDetails({});
          return;
        }

        const json = await response.json();
        const profileDetails = camelizeKeys(json) as unknown as ProfileDetails;

        setDetails(profileDetails);
      } catch (error) {
        console.log(`😵‍💫 error`, { error, inProgress });
        setDetails({});
      } finally {
        setLoading(false);
      }
    };

    if (!accounts?.length) return;
    setLoading(true);

    getCustomerProfile();
  }, [instance, inProgress, accounts]);

  return [details, loading];
};

export { useGetCustomerDetails };
