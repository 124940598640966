import { AccountTier, getProfileEditRequest } from '../../b2c';
import { useAuthContextState, useCustomerProfileState } from '../../context';
import { useIdToken, useLoginRedirect } from '../../hooks';
import { AddressDetailsCard } from '../AddressDetailsCard';

export const AddressDetailsSection = () => {
  const { getUpdateAddressIdToken } = useIdToken();
  const [updateAddressDetails] = useLoginRedirect();
  const { state } = useCustomerProfileState();
  const {
    authState: { customerAccountTier },
  } = useAuthContextState();

  if (!state) return null;

  const {
    profileDetails: {
      customerType,
      address: {
        addressLine1,
        addressLine2,
        addressLine3,
        addressLine4,
        city,
        houseName,
        houseNumber,
        postCode,
        streetName,
        internationalAddressLine1,
        country,
      } = {},
    } = {},
  } = state;

  const ukAddressLine = `${houseNumber || ''} ${
    streetName || internationalAddressLine1 || ''
  }`;

  const handleUpdateAddress = async () => {
    const idToken = await getUpdateAddressIdToken();
    await updateAddressDetails(getProfileEditRequest(idToken));
  };

  // The api is the source of truth for the customer account tier, especially as the id token can be out of date
  // immediately after an upgrade. Check the api first and if it is not available, check the id token.
  const isBasicAccount = customerType
    ? customerType.toLowerCase() === AccountTier.Basic.toLowerCase()
    : customerAccountTier?.toLowerCase() === AccountTier.Basic.toLowerCase();

  if (isBasicAccount) return null;

  return (
    <>
      <h2 className="tfl-personal-details-header">Address</h2>
      <AddressDetailsCard
        houseName={houseName}
        ukAddressLine={ukAddressLine}
        addressLine1={addressLine1}
        addressLine2={addressLine2}
        addressLine3={addressLine3}
        addressLine4={addressLine4}
        town={`${city}`}
        postcode={`${postCode}`}
        country={`${country?.name}`}
        showLoader={!state.profileDetails}
        showAlert={
          state.profileDetails && Object.keys(state.profileDetails).length === 0
        }
        onClick={handleUpdateAddress}
      />
    </>
  );
};
