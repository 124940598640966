import { PersonalDetailsCard } from '..';
import { getProfileEditRequest } from '../../b2c';
import { useCustomerProfileState } from '../../context';
import { useIdToken, useLoginRedirect } from '../../hooks';

export const PersonalDetailsSection = () => {
  const { getUpdateBasicDetailsIdToken } = useIdToken();
  const [updateBasicDetails] = useLoginRedirect();
  const { state } = useCustomerProfileState();
  const { profileDetails } = state;
  const {
    titleDesc,
    firstName,
    lastName,
    emailAddress,
    homePhoneNumber,
    mobilePhoneNumber,
  } = {
    ...profileDetails,
  };

  const handleUpdatePersonalDetails = async () => {
    const idToken = await getUpdateBasicDetailsIdToken();
    await updateBasicDetails(getProfileEditRequest(idToken));
  };

  const getName = (): string | undefined => {
    const onlyPartialData = [titleDesc, firstName, lastName].some(
      (item) => !item
    );

    if (onlyPartialData) return undefined;

    return `${titleDesc} ${firstName} ${lastName}`;
  };

  return (
    <>
      <h2 className="tfl-personal-details-header">Personal details</h2>
      <PersonalDetailsCard
        name={getName()}
        emailAddress={emailAddress}
        otherContactNumber={homePhoneNumber}
        mobileContactNumber={mobilePhoneNumber}
        showLoader={!profileDetails}
        showAlert={profileDetails && Object.keys(profileDetails).length === 0}
        onClick={handleUpdatePersonalDetails}
      />
    </>
  );
};
