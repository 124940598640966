import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { TflIdTokenClaims } from '../b2c/TflIdTokenClaims.types';

const AuthContext = createContext({
  authState: {} as AuthContextState,
  setAuthState: {} as Dispatch<SetStateAction<AuthContextState>>,
});

const AuthContextProvider = ({
  children,
  value = {} as AuthContextState,
}: {
  children: React.ReactNode;
  value?: AuthContextState;
}) => {
  const [authState, setAuthState] = useState<AuthContextState>(value);
  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContextState = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContextState must be used within AuthContext page');
  }
  return context;
};

const Debug = (data?: any) => {
  const { authState } = useAuthContextState();
  return (
    <pre>
      {JSON.stringify(authState || {}, null, 2)}
      <hr />
      {JSON.stringify(data || {}, null, 2)}
      <hr />
    </pre>
  );
};

export interface AuthContextState extends TflIdTokenClaims {}

export { AuthContextProvider, Debug, useAuthContextState };
