import { useEffect, useState } from 'react';

export const useStorageEventNotification = (eventKey: string) => {
  const [eventValue, setEventValue] = useState<string | null>(null);
  useEffect(() => {
    window.addEventListener('storage', eventHandler, false);
    return () => window.removeEventListener('storage', eventHandler);
  }, []);

  const eventHandler = async (event: StorageEvent) => {
    if (event.key !== eventKey) return;
    setEventValue(event.newValue);
  };
  return eventValue;
};
