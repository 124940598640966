import { DashboardCardProps } from './DashboardCard.types';
import './DashboardCard.scss';
import classnames from 'classnames';

export const DashboardCard = ({
  iconImage,
  header,
  description,
  children,
}: DashboardCardProps) => {
  return (
    <div className="tfl-dashboard__card">
      <div
        className={classnames([
          'tfl-dashboard__card',
          `tfl-dashboard__card--${iconImage}`,
        ])}
      ></div>
      <div className="tfl-dashboard__card-label-wrapper">
        <div className="tfl-dashboard__card-header">{header}</div>
        <div className="tfl-dashboard__card-description">{description}</div>
      </div>
      <div className="tfl-dashboard__card-button">{children}</div>
    </div>
  );
};
