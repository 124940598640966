import { useNavigate, useLocation } from 'react-router-dom';
import { Header } from '@tfl/component-library';
import { paths } from '../../router';
import { notifySignOut } from '../../b2c/constants';
import navigationLinks from '../../headerdata.json';
import {
  useHandleB2CErrors,
  useLoginRedirect,
  useLogoutRedirect,
} from '../../hooks';
import { useIsAuthenticated } from '@azure/msal-react';

export const AuthHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [logoutRedirect] = useLogoutRedirect();
  const [loginRedirect] = useLoginRedirect();
  const [hasB2CError] = useHandleB2CErrors();
  const isAuthenticated = useIsAuthenticated();

  const accountProps = {
    isUserAuthenticated: hasB2CError ? isAuthenticated : true,
    handleSignOut: async () => {
      notifySignOut();
      await logoutRedirect();
    },
    handleSignIn: async () => await loginRedirect(),
    handleDashboardButtonClick: () =>
      location.pathname === paths.myAccount.path
        ? window.location.reload()
        : navigate(paths.myAccount.path),
    handleProfileButtonClick: () =>
      location.pathname === paths.profile.path
        ? window.location.reload()
        : navigate(paths.profile.path),
  };

  return (
    <>
      <Header
        data={navigationLinks}
        accountButtonRequired={true}
        accountProps={accountProps}
        homeUrl={process.env.REACT_APP_ROUNDEL_HOME_URL}
      />
    </>
  );
};
