import { RedirectRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { policies } from '../../b2c';
import { LoginRedirectFunction } from './types';
import { useIdToken } from './useIdToken';

/**
 * A wrapper function to invoke MSAL loginRedirect function
 * @description Please check msal Interaction state before making a login redirect request
 * @returns A tuple with one login redirect function
 */
export const useLoginRedirect = (): [LoginRedirectFunction] => {
  const { instance } = useMsal();
  const { getSigninSignupIdTokenWithMfa } = useIdToken();

  const getIdToken = async () => {
    const idToken = await getSigninSignupIdTokenWithMfa();
    return idToken;
  };

  const loginRedirect: LoginRedirectFunction = async (request) => {
    const id_token_hint = await getIdToken();

    // Add MFA hint if missing from the request
    const extraQueryParameters = request?.extraQueryParameters
      ? { ...request?.extraQueryParameters }
      : { id_token_hint };

    const authRequest = {
      ...policies.authorities.signUpSignIn,
      ...(request && { ...request, redirectUri: request.redirectUri }),
      extraQueryParameters,
    } as RedirectRequest;

    try {
      await instance.loginRedirect(authRequest);
    } catch (error) {
      console.log(
        `❌ useLoginRedirect: Error calling b2c policy : ${request?.authority}`,
        { error }
      );
    }

    return Promise.resolve();
  };

  return [loginRedirect];
};
