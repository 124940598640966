import { getMfaMobileNumberUpdateRequest } from '../../b2c';
import { useIdToken, useLoginRedirect } from '../../hooks';
import { MfaMobileNumberCard } from '../MfaMobileNumberCard';

export const MfaMobileNumberSection = () => {
  const { getUpdateMfaMobileNumber } = useIdToken();
  const [updateMobileNumber] = useLoginRedirect();

  const handleUpdateMobileNumber = async () => {
    const idTokenHint = await getUpdateMfaMobileNumber();
    await updateMobileNumber(getMfaMobileNumberUpdateRequest(idTokenHint));
  };

  return (
    <>
      <MfaMobileNumberCard
        subHeader="Authentication mobile number"
        description="We'll use this number to confirm your identity when you're signing in and accessing your account"
        buttonLabel="Update number"
        onClick={handleUpdateMobileNumber}
      />
    </>
  );
};
