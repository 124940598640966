import { PersonalDetailsCardProps } from './PersonalDetailsCard.types';
import { Alert, Button, Loader } from '@tfl/component-library';
import classnames from 'classnames';
import './PersonalDetailsCard.scss';
import { NoValuePlaceholder } from '../NoValuePlaceholder';

export const PersonalDetailsCard = ({
  name,
  emailAddress,
  otherContactNumber,
  mobileContactNumber,
  showLoader = false,
  showAlert = false,
  onClick,
}: PersonalDetailsCardProps) => {
  return (
    <div
      className={classnames([
        'tfl-personal-details',
        {
          'tfl-personal-details--loading': showLoader,
        },
      ])}
    >
      {!showLoader ? (
        <>
          {!showAlert ? (
            <div>
              <div className="tfl-personal-details__name">
                <div className="tfl-personal-details__name-label">Name</div>
                <div>
                  <NoValuePlaceholder>{name}</NoValuePlaceholder>
                </div>
              </div>
              <div className="tfl-personal-details__email">
                <div className="tfl-personal-details__email-label">
                  Email address
                </div>
                <div>
                  <NoValuePlaceholder>{emailAddress}</NoValuePlaceholder>
                </div>
              </div>
              <div className="tfl-personal-details__phone">
                <div className="tfl-personal-details__phone-label">
                  Contact mobile number
                </div>
                <div>
                  <NoValuePlaceholder>{mobileContactNumber}</NoValuePlaceholder>
                </div>
              </div>
              <div className="tfl-personal-details__phone">
                <div className="tfl-personal-details__phone-label">
                  Contact other number
                </div>
                <div>
                  <NoValuePlaceholder>{otherContactNumber}</NoValuePlaceholder>
                </div>
              </div>
            </div>
          ) : (
            <div className="tfl-personal-details__alert">
              <Alert header={'Not available'} alertStyle={'error'} />
            </div>
          )}
          <div>
            <Button
              buttonStyle="secondary"
              value="Update details"
              onClick={onClick}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};
