import { OffersAndPromotionsCardProps } from './OffersAndPromotionsCard.types';
import { Alert, Button, Loader } from '@tfl/component-library';
import classnames from 'classnames';
import './OffersAndPromotionsCard.scss';

export const OffersAndPromotionsCard = ({
  tflOffers,
  trainCompaniesOffers,
  tflDescription,
  trainDescription,
  showLoader = false,
  showAlert = false,
  onClick,
}: OffersAndPromotionsCardProps) => {
  return (
    <div
      className={classnames([
        'tfl-offers-card',
        {
          'tfl-offers-card--loading': showLoader,
        },
      ])}
    >
      {!showLoader ? (
        <>
          {!showAlert ? (
            <div>
              <div className={`tfl-offers-card__tfl--${tflOffers}`}>
                <div className="tfl-offers-card__tfl-tflDescription">
                  {tflDescription}
                  <span className="hidden-clip">
                    {tflOffers === 'yes'
                      ? '. Option selected'
                      : '. Option not selected'}
                  </span>
                </div>
              </div>
              <div
                className={`tfl-offers-card__train--${trainCompaniesOffers}`}
              >
                <div className="tfl-offers-card__train-trainDescription">
                  {trainDescription}
                  <span className="hidden-clip">
                    {trainCompaniesOffers === 'yes'
                      ? '. Option selected'
                      : '. Option not selected'}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="tfl-offers-card__alert">
              <Alert header={'Not available'} alertStyle={'error'} />
            </div>
          )}
          <div>
            <Button
              buttonStyle="secondary"
              value="Update preferences"
              onClick={onClick}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};
