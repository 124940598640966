import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { Button, Tile } from '@tfl/component-library';
import { DashboardCard, Loader, SignOutButton, TfLGoCard } from '../components';
import { TfLAuthenticationTemplate } from '../components/Msal';
import { policies } from '../b2c';
import { paths } from '../router';
import { badgeData } from '../components/TfLGoCard/badgedata';
import { useIdToken, useUpdatePageTitle } from '../hooks';
import '../assets/scss/Dashboard.scss';
import { trackNavigation } from '../adobe-analytics/tracking';

export const Dashboard = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleGoToProfile = () => navigate(paths.profile.path);
  const navigateToHomePage = pathname.includes(paths.signin.path);
  const pageHeading = 'Dashboard';
  useUpdatePageTitle(pageHeading);

  const [idToken, setIdToken] = useState<string>();
  const { getSigninSignupIdTokenWithMfa } = useIdToken();

  useEffect(() => {
    if (navigateToHomePage) navigate(paths.myAccount.path, { replace: true });
  }, [navigateToHomePage]);

  useEffect(() => {
    if (navigateToHomePage) return;
    const getIdToken = async () => {
      const idToken = await getSigninSignupIdTokenWithMfa();
      setIdToken(idToken);
    };
    getIdToken();
  }, [getSigninSignupIdTokenWithMfa, navigateToHomePage]);

  if (navigateToHomePage || !idToken) return <Loader />;

  const authRequest = {
    ...policies.authorities.signUpSignIn,
    extraQueryParameters: { id_token_hint: idToken },
  };

  const handleGoToOyster = () => {
    window.location.href = `${process.env.REACT_APP_GO_TO_OYSTER_URL}`;
  };

  const handleGotoCasc = () => {
    window.location.href = `${process.env.REACT_APP_GO_TO_CONTACTLESS_URL}`;
  };

  const handleGoToSantander = () => {
    trackNavigation('Santander Cycles button');
    return true;
  };

  return (
    <TfLAuthenticationTemplate authRequest={authRequest}>
      <Container className="mt-5 mb-3 tfl-page">
        <Col md={12} lg={12}>
          <Row className="mb-5">
            <h1 className="tfl-page-header">{pageHeading}</h1>
          </Row>
          <Row className="mb-5">
            <Col md={6} lg={4} className="pl-0 pr-3">
              <Button
                buttonStyle="icon-settings"
                value="Profile"
                onClick={handleGoToProfile}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={4} className="pl-0 pr-3 mb-3">
              <DashboardCard
                iconImage="icon-oyster"
                header="Oyster"
                description="View all your cards, journey history and card balance"
              >
                <Button
                  buttonStyle="icon-right-chevron"
                  value="Go to Oyster"
                  onClick={handleGoToOyster}
                />
              </DashboardCard>
            </Col>
            <Col md={6} lg={4} className="pl-0 pr-3 mb-3">
              <DashboardCard
                iconImage="icon-contactless"
                header="Contactless"
                description="View your cards, journey and payment history"
              >
                <Button
                  buttonStyle="icon-right-chevron"
                  value="Go to contactless"
                  onClick={handleGotoCasc}
                />
              </DashboardCard>
            </Col>
            <Col md={6} lg={4} className="tfl-go-card-desktop pl-0 pr-3 mb-3">
              <TfLGoCard
                header="Download TfL Go"
                description="Use our app to plan your journey and check travel in advance"
                appleBadge={badgeData.badges.apple}
                androidBadge={badgeData.badges.android}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6} lg={4} className="pl-0 pr-3 mb-3">
              <Tile
                tileStyle="icon-link-out"
                header="Pay to drive"
                description="Congestion Charge, LEZ, ULEZ"
                href="https://tfl.gov.uk/modes/driving/pay-to-drive-in-london"
                target="_blank"
              />
            </Col>
            <Col md={6} lg={4} className="pl-0 pr-3 mb-3">
              <Tile
                tileStyle="icon-link-out"
                header="Santander Cycles"
                description="Get on your cycle quicker"
                href="https://santandercycles.tfl.gov.uk"
                target="_blank"
                onClick={handleGoToSantander}
              />
            </Col>
            <Col md={6} lg={4} className="tfl-go-card-mobile pl-0 pr-3 mb-3">
              <TfLGoCard
                header="Download TfL Go"
                description="Use our app to plan your journey and check travel in advance"
                appleBadge={badgeData.badges.apple}
                androidBadge={badgeData.badges.android}
              />
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={6} lg={4} className="pl-0 pr-3">
              <SignOutButton />
            </Col>
          </Row>
        </Col>
      </Container>
    </TfLAuthenticationTemplate>
  );
};
