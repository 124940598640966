import { useEffect } from 'react';
import { NOTIFYLOGOUT_KEY } from '../b2c/constants';
import { useLogoutRedirect } from './useLogoutRedirect';

const useSyncStateAcrossTabs = () => {
  const [logoutRedirect] = useLogoutRedirect();

  useEffect(() => window.addEventListener('storage', logoutHandler, false), []);

  const logoutHandler = async (event: StorageEvent) => {
    if (event.key !== NOTIFYLOGOUT_KEY) return;

    localStorage.removeItem(NOTIFYLOGOUT_KEY);

    await logoutRedirect();
  };
};

export default useSyncStateAcrossTabs;
