//
/**
 * A lists of Azure B2C specific error codes.
 * @see https://docs.microsoft.com/en-us/azure/active-directory-b2c/error-codes
 */
export const ErrorCodes = {
  FORGOT_PASSWORD: 'AADB2C90118',
  USER_CANCELLATION: 'AADB2C90091',
  USER_INTERACTION_REQUIRED: 'AADB2C90077',
  EXCEEDED_MAX_RETRIES: 'AADB2C90157',
};

/**
 * A log out event key value pair.
 */
export const NOTIFYLOGOUT_KEY = 'event-logout';

/**
 * Sets the `NOTIFYLOGOUT_KEY` key value pair, to raise a storage account event for any listeners.
 * @returns void
 */
export const notifySignOut = (): void =>
  localStorage.setItem(NOTIFYLOGOUT_KEY, `${Date.now()}`);

export const NOTIFY_SESSION_TIMEOUT_MODAL_SHOWN_KEY =
  'session-timeout-modal-shown';
export const notifySessionTimeoutModalShown = (): void =>
  localStorage.setItem(
    NOTIFY_SESSION_TIMEOUT_MODAL_SHOWN_KEY,
    new Date().toISOString()
  );

export const NOTIFY_SESSION_EXTENDED_BY_USER_KEY = 'session-extended-by-user';
export const notifySessionExtendedByUser = (): void =>
  localStorage.setItem(NOTIFY_SESSION_EXTENDED_BY_USER_KEY, `${Date.now()}`);
/**
 * Azure B2C policies.
 */
export enum Policy {
  Register,
  SignUpSignIn,
  ProfileEdit,
  Mfa,
  PasswordReset,
}

/**
 * An Azure B2C specific custom claim applicable to various policies.
 */
export enum AccountTier {
  Basic = 'Basic',
  Full = 'Full',
  Intermediate = 'Intermediate',
}

/**
 * An Azure B2C specific custom claim options applicable to profile edit policy.
 */
export enum ProfileSection {
  Address = 'Address',
  BasicDetails = 'BasicDetails',
  Passcode = 'Passcode',
  Password = 'Password',
  SecurityQuestion = 'SecurityQuestion',
  OffersPromotions = 'MarketingPreferences',
  Unknown = 'Unknown',
}

/**
 * An Azure B2C specific custom claim options applicable to various policies.
 */
export enum NegotiateMfa {
  Force = 'Force',
  No = 'No',
  Yes = 'Yes',
}

/**
 * A speicific custom claim option intended to be used in Azure B2C styling SPA.
 */
export enum SkipToResetMfa {
  Yes = 'Yes',
  No = 'No',
}

/**
 * A speicific custom claim option intended to be used in Azure B2C styling SPA.
 */
export enum AllowCancelMfa {
  Yes = 'Yes',
  No = 'No',
}

/**
 * Azure B2C specific and custom id token keys.
 */
export const CustomClaimKeys: { [x: string]: string } = {
  ACCOUNT_TIER: 'idTokenHint_AccountTier',
  NEGOTIATE_MFA: 'idTokenHint_NegotiateMfaDirective',
  PROFILE_SECTION_TO_UPDATE: 'idTokenHint_ProfileSectionToUpdate',
  SKIP_TO_RESET_MFA: 'idTokenHint_SkipToResetMfa',
  ALLOW_CANCEL_MFA: 'idTokenHint_AllowCancelMfa',
};
