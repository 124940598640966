import { ErrorPage } from '@tfl/component-library';
import { paths } from '../router';
import { useNavigate } from 'react-router-dom';

export const SessionTimeout = () => {
  const navigate = useNavigate();
  const handleGotoDashboard = () => navigate(paths.myAccount.path);

  return (
    <ErrorPage
      header="You have been signed out due to inactivity. Please sign in again to continue using your account."
      buttonProps={{
        onClick: handleGotoDashboard,
        value: 'Sign in',
        buttonStyle: 'primary',
      }}
    />
  );
};
