import {
  BrowserSystemOptions,
  Configuration,
  LogLevel,
} from '@azure/msal-browser';
import { policies } from '.';

export const authRequest = {
  scopes: ['openid', 'profile'],
};

const otherSystemOptions: BrowserSystemOptions = {
  loggerOptions: {
    loggerCallback: (
      level: LogLevel,
      message: string,
      containsPii: boolean
    ): void => {
      if (containsPii) return;

      if (process.env.NODE_ENV === 'production') return;

      switch (level) {
        case LogLevel.Error:
          return console.error(message);
        case LogLevel.Info:
          return console.info(message);
        case LogLevel.Verbose:
          return console.debug(message);
        case LogLevel.Warning:
          return console.warn(message);
      }
    },
    piiLoggingEnabled: false,
  },
  windowHashTimeout: 60000,
  iframeHashTimeout: 6000,
  loadFrameTimeout: 0,
  asyncPopups: false,
};

export const configuration: Configuration = {
  auth: {
    authority: policies.authorities.signUpSignIn.authority,
    clientId: process.env.REACT_APP_AZURE_B2C_APP_CLIENT_ID ?? '',
    knownAuthorities: [policies.authorityDomain],
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    redirectUri: '/my-account'
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    allowRedirectInIframe: true,
    ...otherSystemOptions,
  },
};
