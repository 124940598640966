import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import {
  EventCallbackFunction,
  EventMessage,
  EventType,
} from '@azure/msal-browser';

import { ErrorCodes } from '../../b2c';

const { USER_INTERACTION_REQUIRED } = ErrorCodes;

const {
  ACQUIRE_TOKEN_BY_CODE_FAILURE,
  ACQUIRE_TOKEN_FAILURE,
  LOGIN_FAILURE,
  SSO_SILENT_FAILURE,
  LOGOUT_FAILURE,
} = EventType;

const isB2cFailureEventType = (eventType: EventType): boolean => {
  return [
    ACQUIRE_TOKEN_BY_CODE_FAILURE,
    ACQUIRE_TOKEN_FAILURE,
    LOGIN_FAILURE,
    LOGOUT_FAILURE,
    SSO_SILENT_FAILURE,
  ].includes(eventType);
};

export const useHandleB2CErrors = (): [string?] => {
  const { instance } = useMsal();
  const [error, setError] = useState<string>();

  const b2cErrorsEventCallback: EventCallbackFunction = async (
    event: EventMessage
  ) => {
    const { eventType, error } = event;
    const { message } = (error as Error) || { message: null };

    if (!message) return;

    if (!isB2cFailureEventType(eventType)) return;

    // User interaction is handled by exceptions caught when acquiring
    // tokens so we ignore it as this lower level
    if (message.includes(USER_INTERACTION_REQUIRED)) return;

    setError(message);
  };

  useEffect(() => {
    const callbackId = instance.addEventCallback(b2cErrorsEventCallback);

    return () => {
      if (!callbackId) return;

      instance.removeEventCallback(callbackId);
    };
  }, [instance, b2cErrorsEventCallback]);

  return [error];
};
