import { Alert } from '@tfl/component-library';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ProfileDetails } from '../../api/api.types';
import { useGetCustomerDetails } from '../../api/hooks/useGetCustomerDetails';
import { useCustomerProfileState } from '../../context';

export const ProfilePageAlert = () => {
  const [details] = useGetCustomerDetails();
  const { setState } = useCustomerProfileState();

  useEffect(() => {
    if (!details) return;

    setState((prevState) => ({
      ...prevState,
      profileDetails: details as ProfileDetails,
    }));
  }, [details]);

  if (!details) return null;

  return (
    <>
      {details && Object.keys(details).length === 0 ? (
        <Row className="mb-5">
          <Col md={12} lg={8} className="pl-0 pr-4">
            <Alert
              header={'Something went wrong'}
              description={
                "We couldn't retrieve some of your information. Refresh the page, or try signing in again."
              }
              alertStyle={'error'}
            />
          </Col>
        </Row>
      ) : null}
    </>
  );
};
