import { BadgeData } from "./TfLGoCard.types";

const badgeData: BadgeData = {
  badges: {
    apple: {
      text: 'icon-apple-badge',
      url: process.env.REACT_APP_TFLGO_IOS_URL!,
      label: 'Download the TfL Go app on the Apple app store',
    },
    android: {
      text: 'icon-android-badge',
      url: process.env.REACT_APP_TFLGO_ANDROID_URL!,
      label: 'Download the TfL Go app on the Google Play store',
    },
  },
};

export { badgeData };
