import { useEffect } from 'react';
import { AccountInfo, BrowserUtils, InteractionStatus } from '@azure/msal-browser';
import { ErrorPage } from '@tfl/component-library';
import { useLogoutRedirect } from '../hooks';
import { useMsal } from '@azure/msal-react';
import { notifySignOut } from '../b2c/constants';

export const Signout = () => {
  const [logoutRedirect] = useLogoutRedirect();
  
  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    if (inProgress !== InteractionStatus.None) return;
    notifySignOut();

    const signOutLocally = async (account: AccountInfo) => {
      await instance.logoutRedirect({
        account,
        onRedirectNavigate: () => false,
      });
    };

    if (BrowserUtils.isInIframe()) {
      for (const account of accounts) {
        signOutLocally(account);
      }
    }
    else {
      logoutRedirect();
    }
  }, [inProgress, accounts]);

  return <ErrorPage header="You have successfully signed out." />;
};
