import { Button } from '@tfl/component-library';
import { notifySignOut } from '../../b2c/constants';
import { useLogoutRedirect } from '../../hooks';

export const SignOutButton = () => {
  const [logoutRedirect] = useLogoutRedirect();

  const handleSignOut = async () => {
    notifySignOut();
    await logoutRedirect();
  };

  return <Button onClick={handleSignOut} value="Sign out" />;
};
