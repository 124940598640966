import { useEffect } from 'react';

export const useUpdatePageTitle = (heading: string) => {
  useEffect(() => {
    if (!heading) return;

    document.title = [heading, 'Transport for London'].join(' - ');
  }, [heading]);
};

export default useUpdatePageTitle;
