import { AccountInfo, RedirectRequest } from '@azure/msal-browser';

export const policies = {
  authorities: {
    signUpSignIn: {
      scopes: ['openid', 'offline_access'],
      authority: `${process.env.REACT_APP_AZURE_B2C_AUTHORITY}`,
      redirectUri: `/my-account`,
    },
    forgotPassword: {
      scopes: ['openid'],
      authority: `${process.env.REACT_APP_AZURE_B2C_AUTHORITY_PASSWORD_RESET}`,
    },
    mfa: {
      scopes: ['openid'],
      authority: `${process.env.REACT_APP_AZURE_B2C_AUTHORITY_MFA}` || '',
      redirectUri: `/my-account/profile`,
    },
    signUpSignInAcquireToken: {
      scopes: [
        'openid',
        'offline_access',
        process.env.REACT_APP_AZURE_B2C_DCR_API_TOKEN_SCOPE || '',
      ],
      authority: `${process.env.REACT_APP_AZURE_B2C_AUTHORITY}` || '',
    },
    profileEdit: {
      scopes: ['openid'],
      authority: `${process.env.REACT_APP_AZURE_B2C_AUTHORITY_PROFILE_EDIT}`,
      redirectUri: `/my-account/profile`,
    },
  },
  authorityDomain: `${process.env.REACT_APP_AZURE_B2C_AUTHORITY_DOMAIN}`,
};

export const getProfileEditRequest = (
  idTokenHint: string
): RedirectRequest | undefined => ({
  ...policies.authorities.profileEdit,
  extraQueryParameters: {
    id_token_hint: idTokenHint,
  },
});

export const getMfaMobileNumberUpdateRequest = (
  idTokenHint: string
): RedirectRequest | undefined => ({
  ...policies.authorities.mfa,
  extraQueryParameters: {
    id_token_hint: idTokenHint,
  },
});

export const getSigInWithNegotiateMfRequest = (
  idTokenHint: string
): RedirectRequest | undefined => ({
  ...policies.authorities.signUpSignIn,
  extraQueryParameters: {
    id_token_hint: idTokenHint,
  },
});

export const getSigInWithNegotiateMfaForAccessTokenRequest = (
  account: AccountInfo,
  idTokenHint: string
): RedirectRequest | undefined => ({
  account,
  ...policies.authorities.signUpSignInAcquireToken,
  extraQueryParameters: {
    id_token_hint: idTokenHint,
  },
});

export const policyDetails = {
  signUpSignIn: {
    name: `${process.env.REACT_APP_AZURE_B2C_SIGNIN_POLICY_NAME}`,
    authority: `${process.env.REACT_APP_AZURE_B2C_AUTHORITY}`,
  },
  forgotPassword: {
    name: 'B2C_1A_PasswordReset',
    authority: `${process.env.REACT_APP_AZURE_B2C_AUTHORITY_PASSWORD_RESET}`,
  },
  profileEdit: {
    name: 'B2C_1A_ProfileEdit',
    authority: `${process.env.REACT_APP_AZURE_B2C_AUTHORITY_PROFILE_EDIT}`,
  },
  mfa: {
    name: 'B2C_1A_MFA',
    authority: `${process.env.REACT_APP_AZURE_B2C_AUTHORITY_MFA}`,
  },
};
