import { Button } from '@tfl/component-library';
import { getProfileEditRequest } from '../../b2c';
import { useIdToken, useLoginRedirect } from '../../hooks';
import { FullAccountTierContent } from '../FullAccountTierContent';
import './SecuritySection.scss';

export const SecuritySection = () => {
  const {
    getUpdatePasswordIdToken,
    getUpdateSecurityQuestionIdToken,
    getUpdatePasscodeIdToken,
  } = useIdToken();

  const [updateSecurityDetails] = useLoginRedirect();

  const handleUpdatePassword = async () => {
    const idToken = await getUpdatePasswordIdToken();
    await updateSecurityDetails(getProfileEditRequest(idToken));
  };

  const handleUpdateSecurityQuestion = async () => {
    const idToken = await getUpdateSecurityQuestionIdToken();
    await updateSecurityDetails(getProfileEditRequest(idToken));
  };

  const handleUpdatePasscode = async () => {
    const idToken = await getUpdatePasscodeIdToken();
    await updateSecurityDetails(getProfileEditRequest(idToken));
  };

  return (
    <>
      <h2 className="tfl-security-details-header">Security</h2>
      <div className="tfl-security-section__wrapper">
        <div className="tfl-security-section__button-wrapper">
          <Button
            buttonStyle="icon-right-chevron"
            value="Update password"
            onClick={handleUpdatePassword}
            id="tfl-update-password"
          />
        </div>
        <FullAccountTierContent>
          <>
            <div className="tfl-security-section__button-wrapper">
              <Button
                id="tfl-update-security-question"
                buttonStyle="icon-right-chevron"
                value="Update security question"
                onClick={handleUpdateSecurityQuestion}
                className="tfl-security-section__button"
              />
            </div>
            <div className="tfl-security-section__button-wrapper">
              <Button
                id="tfl-update-passcode"
                buttonStyle="icon-right-chevron"
                value="Update passcode"
                onClick={handleUpdatePasscode}
                className="tfl-security-section__button"
              />
            </div>
          </>
        </FullAccountTierContent>
      </div>
    </>
  );
};
