import { NoValuePlaceholderProps } from './NoValuePlaceholder.types';

export const NoValuePlaceholder = ({ children }: NoValuePlaceholderProps) => {
  const isEmpty = !children || !children.trim();
  return isEmpty ? (
    <span aria-label="No value">-</span>
  ) : (
    <span>{children}</span>
  );
};
