import {
  EventCallbackFunction,
  EventMessage,
  EventType,
  InteractionType,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { useLoginRedirect } from '..';
import { ErrorCodes, policies } from '../../b2c';

const useHandleForgotPassword = () => {
  const { instance } = useMsal();
  const [resetPassword] = useLoginRedirect();

  const forgotPasswordEventCallback: EventCallbackFunction = async (
    event: EventMessage
  ) => {
    if (
      event.eventType !== EventType.LOGIN_FAILURE &&
      event.eventType !== EventType.ACQUIRE_TOKEN_FAILURE
    )
      return;

    if (event.error?.message.indexOf(ErrorCodes.FORGOT_PASSWORD) === -1) return;

    if (event.interactionType !== InteractionType.Redirect) return;

    await resetPassword(policies.authorities.forgotPassword);
  };

  useEffect(() => {
    const callbackId = instance.addEventCallback(forgotPasswordEventCallback);

    return () => {
      if (callbackId) instance.removeEventCallback(callbackId);
    };
  }, [instance, forgotPasswordEventCallback]);
};

export default useHandleForgotPassword;
