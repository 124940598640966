import { RouteObject } from 'react-router-dom';
import { CustomerProfileProvider } from '../context';
import {
  BadRequestError,
  Dashboard,
  InternalServerError,
  PageNotFound,
  Profile,
  ServiceUnavailableError,
  Signout,
} from '../pages';
import { Paths } from './router.types';
import { SessionTimeout } from '../pages/SessionTimeout';

export const paths: Paths = {
  myAccount: { path: '/', element: <Dashboard /> },
  signin: { path: '/sign-in', element: <Dashboard /> },
  signout: { path: '/sign-out', element: <Signout /> },
  profile: {
    path: '/profile',
    element: (
      <CustomerProfileProvider>
        <Profile />
      </CustomerProfileProvider>
    ),
  },
  
  sessionTimeout: { path: '/session-timeout', element: <SessionTimeout /> },
  wrongEntry: { path: '/wrong-entry', element: <BadRequestError /> },
  tryAgainNow: { path: '/try-again-now', element: <ServiceUnavailableError /> },
  tryAgainLater: { path: '/try-again-later', element: <InternalServerError /> },
  allOtherPaths: { path: '*', element: <PageNotFound /> },
};

const routes = Object.values<RouteObject>(paths);

export default routes;
