import { IPublicClientApplication } from '@azure/msal-browser';
import { AccountTier } from '../b2c';
import { Claims } from '../b2c/TflIdTokenClaims.types';

export function useGetHighestCustomerAccountTier(
  instance: IPublicClientApplication
) {
  // Accounts are per policy, so we need to get all accounts and check the tier for each.
  // It is possible to start with a basic or intermediate account and then upgrade to full via the Oyster Adapter.
  // In this case you will end up with two accounts (until you sign out/in again), one basic/intermediate and one full.
  // We use the highest tier to ensure that the customer has visibility of all their data.
  const accounts = instance.getAllAccounts();
  if (!accounts || accounts.length == 0) return null;
  const allTiers = accounts.map((account) => {
    const claims = account?.idTokenClaims as unknown as Claims;
    return claims.customerAccountTier;
  });

  const highestTier = allTiers.includes(AccountTier.Full)
    ? AccountTier.Full
    : allTiers.includes(AccountTier.Intermediate)
    ? AccountTier.Intermediate
    : allTiers.includes(AccountTier.Basic)
    ? AccountTier.Basic
    : null;
  return highestTier;
}
