import { AccountTier } from '../../b2c';
import { useAuthContextState, useCustomerProfileState } from '../../context';
import { PropsWithChildren } from 'react';

export const FullAccountTierContent: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    authState: { customerAccountTier },
  } = useAuthContextState();

  const { state } = useCustomerProfileState();
  const { profileDetails: { customerType } = {} } = state;

  const isFullAccountTier =
    customerType?.toLowerCase() === AccountTier.Full.toLowerCase() ||
    customerAccountTier?.toLowerCase() === AccountTier.Full.toLowerCase();

  return isFullAccountTier ? <>{children}</> : null;
};
