import { Modal, Button } from '@tfl/component-library';
import { useInterval } from '../../hooks';
import { useState } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

export interface SessionTimeoutWarningModalProps {
  dateModalShownAt: Date;
  onSignOutSelected: () => void;
  onStayOnlineSelected: () => void;
  onSessionTimedOut: () => void;
}

export const SessionTimeoutWarningModal = ({
  dateModalShownAt,
  onSignOutSelected,
  onStayOnlineSelected,
  onSessionTimedOut,
}: SessionTimeoutWarningModalProps) => {
  const { inProgress } = useMsal();
  const totalTimeUntilSignOut = Number(
    process.env.REACT_APP_SESSION_LOGOUT_DELAY_S
  );
  const [timeUntilSignOut, setTimeUntilSignOut] = useState(
    totalTimeUntilSignOut
  );
  useInterval(() => {
    const remainingTime = Math.round(
      totalTimeUntilSignOut -
        (new Date().getTime() - dateModalShownAt.getTime()) / 1000
    );
    // the time can go negative if the interaction status is not yet None
    setTimeUntilSignOut(remainingTime < 0 ? 0 : remainingTime);
    if (timeUntilSignOut === 0 && inProgress === InteractionStatus.None) {
      onSessionTimedOut();
      return;
    }
  }, 1000);

  return (
    <Modal
      clickAwayClosesModal={false}
      hideCloseButton={true}
      narrowModal={true}
      role='alertdialog'
      toggleModalCallBack={() => {
        onSignOutSelected();
      }}
      headerText={"You've been inactive for a while"}
    >
      <p>
        For security we&apos;ll sign you out automatically. Click &quot;Stay
        online&quot; to continue using your session.
      </p>
      <p aria-live={timeUntilSignOut % 10 === 0 ? 'assertive' : 'off'}>
        You will be signed out in {timeUntilSignOut} seconds
      </p>
      <Button
        buttonStyle="primary"
        value="Stay online"
        onClick={onStayOnlineSelected}
      />
      <Button
        buttonStyle="secondary"
        value="Sign out"
        onClick={onSignOutSelected}
      />
    </Modal>
  );
};
