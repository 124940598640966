declare global {
  // See https://experienceleague.adobe.com/docs/experience-platform/tags/client-side/satellite-object.html
  const _satellite: any;
}

export const trackNavigation = (target: string) => {
  if (typeof _satellite !== 'undefined') {
    _satellite.track('dcp', { navigation_tracking: target });
  }
};
