import { Loader as TflLoader } from '@tfl/component-library';
import { Container, Row, Col } from 'react-bootstrap';
import './Loader.scss';

const Loader = ({
  label = 'Authentication in progress, please wait.',
}: {
  label?: string;
}) => (
  <Container className="mt-5 tfl-loader-container">
    <Row>
      <Col className="mt-5">
        <TflLoader label={label} />
      </Col>
    </Row>
  </Container>
);

export default Loader;
