import { NegotiateMfa, Policy, ProfileSection } from '../../../b2c';
import { SkipToResetMfa, AllowCancelMfa } from '../../../b2c/constants';
import { createIdToken } from './idToken';
import { CustomClaimsOptions, IdTokenFunctions } from './types';

/**
 * useIdToken
 * @return {function} Returns a set of functions to retreive pre configured id tokens.
 */
const useIdToken = (): IdTokenFunctions => ({
  getSigninSignupIdTokenWithMfa,
  getUpdateAddressIdToken,
  getUpdateBasicDetailsIdToken,
  getUpdateMfaMobileNumber,
  getUpdateOffersPromotionsIdToken,
  getUpdatePasscodeIdToken,
  getUpdatePasswordIdToken,
  getUpdateSecurityQuestionIdToken,
  getNegotiateMfaForceIdToken,
});

const getIdToken = async (customClaimsOptions: CustomClaimsOptions) =>
  await createIdToken(customClaimsOptions);

const getSigninSignupIdTokenWithMfa = async () =>
  await getIdToken({
    policy: Policy.SignUpSignIn,
    negotiateMfa: NegotiateMfa.Yes,
  });

const getUpdateBasicDetailsIdToken = async () =>
  await getIdToken({
    policy: Policy.ProfileEdit,
    profileSection: ProfileSection.BasicDetails,
    negotiateMfa: NegotiateMfa.Yes,
  });

const getUpdatePasswordIdToken = async () =>
  await getIdToken({
    policy: Policy.ProfileEdit,
    profileSection: ProfileSection.Password,
    negotiateMfa: NegotiateMfa.Yes,
  });

const getUpdatePasscodeIdToken = async () =>
  await getIdToken({
    policy: Policy.ProfileEdit,
    profileSection: ProfileSection.Passcode,
    negotiateMfa: NegotiateMfa.Yes,
  });

const getUpdateSecurityQuestionIdToken = async () =>
  await getIdToken({
    policy: Policy.ProfileEdit,
    profileSection: ProfileSection.SecurityQuestion,
    negotiateMfa: NegotiateMfa.Yes,
  });

const getUpdateAddressIdToken = async () =>
  await getIdToken({
    policy: Policy.ProfileEdit,
    profileSection: ProfileSection.Address,
    negotiateMfa: NegotiateMfa.Yes,
  });

const getUpdateOffersPromotionsIdToken = async () =>
  await getIdToken({
    policy: Policy.ProfileEdit,
    profileSection: ProfileSection.OffersPromotions,
    negotiateMfa: NegotiateMfa.Yes,
  });

const getUpdateMfaMobileNumber = async () =>
  await getIdToken({
    policy: Policy.Mfa,
    negotiateMfa: NegotiateMfa.Force,
    skipToResetMfa: SkipToResetMfa.Yes,
    allowCancelMfa: AllowCancelMfa.Yes,
  });

const getNegotiateMfaForceIdToken = async () =>
  await getIdToken({
    policy: Policy.Mfa,
    negotiateMfa: NegotiateMfa.Force,
  });

export default useIdToken;
