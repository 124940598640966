import { useEffect, useState } from 'react';
import { useLogoutRedirect, useIdleStatus } from '../../hooks';
import { SessionTimeoutWarningModal } from './SessionTimeoutWarningModal';
import { paths } from '../../router';
import {
  NOTIFY_SESSION_EXTENDED_BY_USER_KEY,
  NOTIFY_SESSION_TIMEOUT_MODAL_SHOWN_KEY,
  notifySessionExtendedByUser,
  notifySessionTimeoutModalShown,
  notifySignOut,
} from '../../b2c/constants';
import { useLocation } from 'react-router-dom';
import { useStorageEventNotification } from '../../hooks';

export const SessionTimeoutManager = () => {
  const [logoutRedirect] = useLogoutRedirect();
  const location = useLocation();
  const [monitorSessionTimeout, setMonitorSessionTimeout] = useState(
    location.pathname !== paths.sessionTimeout.path
  );
  const isIdle = useIdleStatus({
    monitorActivity: monitorSessionTimeout,
    timeToIdle: process.env.REACT_APP_SESSION_IDLE_NOTIFICATION_DURATION_MS,
    inactivityEvents: [],
  });
  const [showModal, setShowModal] = useState(false);
  const otherTabSessionModalLastShownAt = useStorageEventNotification(
    NOTIFY_SESSION_TIMEOUT_MODAL_SHOWN_KEY
  );
  const otherTabSessionLastExtendedAt = useStorageEventNotification(
    NOTIFY_SESSION_EXTENDED_BY_USER_KEY
  );
  const [dateModalShownAt, setDateModalShownAt] = useState<Date | null>(null);

  useEffect(() => {
    if (isIdle) {
      setMonitorSessionTimeout(false);
      setShowModal(true);
      setDateModalShownAt(new Date());
      notifySessionTimeoutModalShown();
    }
  }, [isIdle]);

  useEffect(() => {
    if (otherTabSessionModalLastShownAt !== null) {
      setMonitorSessionTimeout(false);
      setShowModal(true);
      setDateModalShownAt(new Date(otherTabSessionModalLastShownAt));
    }
  }, [otherTabSessionModalLastShownAt]);

  useEffect(() => {
    if (otherTabSessionLastExtendedAt !== null) {
      setMonitorSessionTimeout(true);
      setShowModal(false);
    }
  }, [otherTabSessionLastExtendedAt]);

  const handleSignOutSelected = async () => {
    setMonitorSessionTimeout(false);
    setShowModal(false);

    notifySignOut();
    await logoutRedirect();
  };

  const handleStayOnlineSelected = () => {
    setMonitorSessionTimeout(true);
    setShowModal(false);
    notifySessionExtendedByUser();
  };

  const handleSessionTimedOut = async () => {
    setMonitorSessionTimeout(false);

    notifySignOut();
    await logoutRedirect({
      postLogoutRedirectUri: '/my-account' + paths.sessionTimeout.path,
    });
  };

  return showModal ? (
    <SessionTimeoutWarningModal
      dateModalShownAt={dateModalShownAt!}
      onSignOutSelected={handleSignOutSelected}
      onStayOnlineSelected={handleStayOnlineSelected}
      onSessionTimedOut={handleSessionTimedOut}
    />
  ) : null;
};
