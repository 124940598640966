import { SignJWT } from 'jose';
import {
  AccountTier,
  CustomClaimKeys,
  NegotiateMfa,
  Policy,
  ProfileSection,
} from '../../../b2c/constants';
import { secrets, jwtPayload } from './config';
import { CustomClaims, CustomClaimsOptions } from './types';

/**
 * Generates an id token hint for a set of custom claims
 * @type {Function}
 * @param {CustomClaimsOptions} customClaimsOptions A set of custom claim options
 * @returns {PromiseLike<string>} An id token hint Promise<string>
 * @see https://docs.microsoft.com/en-us/azure/active-directory-b2c/id-token-hint#token-format
 */
export const createIdToken: Function = async (
  customClaimsOptions: CustomClaimsOptions
): Promise<string> => {
  const privateKey = new TextEncoder().encode(secrets.idTokenHintSigningKey);

  const customClaims = getCustomClaims(customClaimsOptions);

  const { aud, exp, iss, protectedHeader } = jwtPayload;

  const idToken = await new SignJWT(customClaims)
    .setProtectedHeader(protectedHeader)
    .setIssuedAt()
    .setIssuer(iss)
    .setAudience(aud)
    .setExpirationTime(exp)
    .sign(privateKey);

  return idToken;
};

/**
 * Gets custom claims to include in the id token hint.
 * @type {Function}
 * @param customClaimsOptions A set of custom claim options
 * @returns {CustomClaims} B2C Policy Custom claims
 */
const getCustomClaims: Function = (
  customClaimsOptions: CustomClaimsOptions
): CustomClaims => {
  const {
    policy,
    accountTier = AccountTier.Basic,
    profileSection = ProfileSection.Unknown,
    negotiateMfa = NegotiateMfa.Yes,
    skipToResetMfa = null,
    allowCancelMfa = null,
  } = customClaimsOptions;

  const {
    ACCOUNT_TIER,
    NEGOTIATE_MFA,
    PROFILE_SECTION_TO_UPDATE,
    SKIP_TO_RESET_MFA,
    ALLOW_CANCEL_MFA,
  } = CustomClaimKeys;

  const defaultClaims = {
    [ACCOUNT_TIER]: accountTier,
    [NEGOTIATE_MFA]: negotiateMfa,
  };

  const optionalClaims = {
    ...(skipToResetMfa && { [SKIP_TO_RESET_MFA]: skipToResetMfa }),
    ...(allowCancelMfa && { [ALLOW_CANCEL_MFA]: allowCancelMfa }),
  };

  const ProfileEditCustomClaims = {
    ...defaultClaims,
    ...optionalClaims,
    [PROFILE_SECTION_TO_UPDATE]: profileSection,
  };

  const MfaEditCustomClaims = {
    ...defaultClaims,
    ...optionalClaims,
    ...(negotiateMfa && {
      [NEGOTIATE_MFA]: negotiateMfa,
    }),
  };

  switch (policy) {
    case Policy.ProfileEdit:
      return ProfileEditCustomClaims;
    case Policy.Mfa:
      return MfaEditCustomClaims;
    default:
      return { ...defaultClaims, ...optionalClaims };
  }
};
