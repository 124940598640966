import { AuthError, BrowserAuthErrorMessage } from '@azure/msal-browser';
import { ErrorPage } from '@tfl/component-library';
import { useEffect } from 'react';
import { ErrorCodes } from '../../b2c';
import { useLoginRedirect } from '../../hooks';
import LoadingComponent from './LoadingComponent';

const ErrorComponent = ({ error }: { error: AuthError | null }) => {
  const [loginRedirect] = useLoginRedirect();

  useEffect(() => {
    if (!error) return;

    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2897#issuecomment-763169441
    if (error.errorCode !== BrowserAuthErrorMessage.noCachedAuthorityError.code)
      return;

    loginRedirect();
  }, [error, loginRedirect]);

  if (error?.message.includes(ErrorCodes.FORGOT_PASSWORD))
    return <LoadingComponent />;

  return (
    <ErrorPage
      header="There was an error signing you in"
      buttonProps={{
        buttonStyle: 'primary',
        value: 'Try again',
        onClick: async () => await loginRedirect(),
      }}
    />
  );
};

export default ErrorComponent;
