import { useMsal } from '@azure/msal-react';
import { IUseLogoutRedirect, ILogoutRedirect } from './types';
import { policies } from '../b2c';
import {
  AccountInfo,
  EndSessionRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { useCallback } from 'react';
import { Claims } from '../b2c/TflIdTokenClaims.types';
import { policyDetails } from '../b2c/policies';
import { useIdToken } from './msal/useIdToken';

export const useLogoutRedirect: IUseLogoutRedirect = () => {
  const { instance } = useMsal();
  const { getSigninSignupIdTokenWithMfa } = useIdToken();

  function findAccount(accounts: AccountInfo[], policyName: string) {
    const account = accounts.find(
      (account) =>
        (
          account?.idTokenClaims as unknown as Claims
        ).trustFrameworkPolicy?.toLowerCase() === policyName.toLowerCase()
    );
    return account;
  }

  // To sign out we must use the authority relevant to the account we signed in with
  // (and accounts are per policy and there will be multiple policies in play when using
  // the app).
  function getAccountAndAuthorityForSignOut(
    accounts: AccountInfo[]
  ): [AccountInfo, string] {
    let account = findAccount(accounts, policyDetails.signUpSignIn.name);
    if (account) {
      return [account, policyDetails.signUpSignIn.authority];
    }

    account = findAccount(accounts, policyDetails.forgotPassword.name);
    const policyName = (
      accounts[0]?.idTokenClaims as unknown as Claims
    ).trustFrameworkPolicy?.toLowerCase();

    if (account) {
      return [account, policyDetails.forgotPassword.authority];
    }

    if (policyName === policyDetails.profileEdit.name.toLowerCase()) {
      return [accounts[0], policyDetails.profileEdit.authority];
    }

    if (policyName === policyDetails.mfa.name.toLowerCase()) {
      return [accounts[0], policyDetails.mfa.authority];
    }

    return [accounts[0], policyDetails.signUpSignIn.authority];
  }

  const logoutWithAcquireTokenSilent = useCallback(
    async (logoutRequest: EndSessionRequest | undefined) => {
      let endSessionRequest = logoutRequest ?? ({} as EndSessionRequest);
      let idTokenForSignOut: string | undefined;
      const accounts = instance.getAllAccounts();
      const idTokenHint = await getSigninSignupIdTokenWithMfa();

      let silentAuthRequest: SilentRequest = {
        scopes: policies.authorities.signUpSignIn.scopes,
        authority: policies.authorities.signUpSignIn.authority,
        extraQueryParameters: {
          id_token_hint: idTokenHint,
        },
      };

      try {
        if (accounts && accounts.length > 0) {
          const [signInAccount, signInAuthority] =
            getAccountAndAuthorityForSignOut(accounts);
          silentAuthRequest.account = signInAccount;
          silentAuthRequest.authority = signInAuthority;
        }
        const authenticationResult = await instance.acquireTokenSilent(
          silentAuthRequest
        );

        idTokenForSignOut = authenticationResult.idToken;
      } catch (error) {
        //If a server session was not found then we should perform a local logout
        endSessionRequest.onRedirectNavigate = () => false;
      }
      // No account is passed in the request which ensures all accounts are logged out locally
      await instance.logoutRedirect({
        ...endSessionRequest,
        idTokenHint: idTokenForSignOut,        
      });
    },
    [instance]
  );

  const logoutRedirect: ILogoutRedirect = async (logoutRequest) => {
    try {
      await logoutWithAcquireTokenSilent(logoutRequest);
    } catch (error) {
      console.log('logout with redirect failed', error);
    }
  };

  return [logoutRedirect];
};
