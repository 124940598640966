import { useLocation, useRoutes } from 'react-router-dom';
import { CookieConsent, Footer, SkipLinks } from '@tfl/component-library';
import { AuthHeader, Loader, ScrollToTop } from './components';
import navigationLinks from './footerdata.json';
import {
  useEnsureSingleCustomer,
  useHandleBrowserBack,
  useHandleForgotPassword,
  useSyncSessionWithServer,
  useSyncStateAcrossTabs,
} from './hooks';
import './assets/scss/App.scss';
import routes from './router';
import { SessionTimeoutManager } from './components';

const App = () => {
  const contents = useRoutes(routes);
  const { pathname } = useLocation();
  const basePath = process.env.PUBLIC_URL;
  const fullPath = pathname === '/' ? basePath : `${basePath}${pathname}`;
  const [serverSyncComplete] = useSyncSessionWithServer();

  useHandleBrowserBack();
  useSyncStateAcrossTabs();
  useHandleForgotPassword();
  useEnsureSingleCustomer();

  return (
    <>
      <SkipLinks
        skipToContentLink={`${fullPath}#main-content`}
        skipToFooterLink={`${fullPath}#footer`}
      />
      <AuthHeader />
      <main id="main-content" className="tfl-content">
        <ScrollToTop>
          {serverSyncComplete ? contents : <Loader />}
          <CookieConsent cbid={process.env.REACT_APP_COOKIEBOT_ID ?? ''} />
        </ScrollToTop>
      </main>
      <Footer data={navigationLinks} />
      {serverSyncComplete && <SessionTimeoutManager />}
    </>
  );
};

export default App;
