import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { Button, ErrorPage } from '@tfl/component-library';
import {
  useHandleB2CErrors,
  useIdToken,
  useLoginRedirect,
  useLogoutRedirect,
  useUpdatePageTitle,
} from '../hooks';
import {
  AddressDetailsSection,
  Loader,
  MfaMobileNumberSection,
  OffersAndPromotionsSection,
  PersonalDataSection,
  PersonalDetailsSection,
  ProfilePageAlert,
  SecuritySection,
  SignOutButton,
  TfLAuthenticationTemplate,
} from '../components';
import { paths } from '../router';
import { policies } from '../b2c/policies';
import { useAuthContextState } from '../context';
import { ErrorCodes } from '../b2c';
import '../assets/scss/Profile.scss';
import { useGetHighestCustomerAccountTier } from '../hooks/useGetHighestCustomerAccountTier';

export const Profile = () => {
  const navigate = useNavigate();
  const { setAuthState } = useAuthContextState();
  const [loginRedirect] = useLoginRedirect();
  const [b2cError] = useHandleB2CErrors();
  const { FORGOT_PASSWORD, EXCEEDED_MAX_RETRIES, USER_CANCELLATION } = ErrorCodes;
  const [logoutRedirect] = useLogoutRedirect();

  const pageHeading = 'Profile';
  useUpdatePageTitle(pageHeading);
  const handleGotoDashboard = () => navigate(paths.myAccount.path);

  const { instance, inProgress } = useMsal();

  // Accounts are per policy, so we need to get all accounts and check the tier for each.
  // It is possible to start with a basic or intermediate account and then upgrade to full via the Oyster Adapter.
  // In this case you will end up with two accounts (until you sign out/in again), one basic/intermediate and one full.
  // We use the highest tier to ensure that the customer has visibility of all their data.
  const customerAccountTier = useGetHighestCustomerAccountTier(instance);

  useEffect(() => {
    if (inProgress !== InteractionStatus.None) return;

    setAuthState((prevState) => {
      return {
        ...prevState,
        customerAccountTier,
      };
    });
  }, [customerAccountTier, inProgress]);

  const handleLoginRedirect = async () => {
    await loginRedirect({
      redirectUri: ['/my-account', paths.profile.path].join(''),
    } as RedirectRequest);
  };

  const [idToken, setIdToken] = useState<string>();
  const { getSigninSignupIdTokenWithMfa } = useIdToken();
  useEffect(() => {
    if (inProgress !== InteractionStatus.None) return;

    const getIdToken = async () => {
      const idToken = await getSigninSignupIdTokenWithMfa();
      setIdToken(idToken);
    };
    getIdToken();
  }, [getSigninSignupIdTokenWithMfa, inProgress]);

  // if the user fails to correctly update their address 3 times the user is logged
  // out (of the profile edit policy) and an error is returned. However, the sign account
  // is still valis and it is possible to retrieve api data so we must log them out of the
  // policy to ensure a consistent experience.
  useEffect(() => {
    if (inProgress !== InteractionStatus.None) return;

    if (b2cError && b2cError?.includes(EXCEEDED_MAX_RETRIES)) {
      const logout = async () => {
        await logoutRedirect({
          postLogoutRedirectUri: '/my-account/profile',
        });
      };

      logout();
    }
  }, [inProgress]);

  if (inProgress !== InteractionStatus.None) return <Loader />;

  if (b2cError && b2cError?.includes(EXCEEDED_MAX_RETRIES)) return <Loader />;

  let isErrorToIgnore = b2cError && (b2cError.includes(USER_CANCELLATION) || b2cError?.includes(FORGOT_PASSWORD));

  if (b2cError && !isErrorToIgnore) {
    return (
      <ErrorPage
        header="There was an error signing you in"
        buttonProps={{
          buttonStyle: 'primary',
          value: 'Try again',
          onClick: handleLoginRedirect,
        }}
      />
    );
  }

  if (!idToken) return <Loader />;

  const authRequest: RedirectRequest = {
    ...policies.authorities.signUpSignIn,
    redirectUri: '/my-account/profile',
    extraQueryParameters: { id_token_hint: idToken },
  };

  return (
    <TfLAuthenticationTemplate authRequest={authRequest}>
      <Container className="mt-5 mb-3 tfl-page">
        <Col md={12} lg={12}>
          <Row className="mb-5">
            <h1 className="tfl-page-header">{pageHeading}</h1>
          </Row>
          <Row className="mb-5">
            <Col md={6} lg={4} className="pl-0 pr-4">
              <Button
                buttonStyle="icon-right-chevron"
                value="Dashboard"
                onClick={handleGotoDashboard}
              />
            </Col>
          </Row>
          <ProfilePageAlert />
          <Row className="">
            <Col md={6} lg={4} className="pl-0 pr-4 tfl-card-group-wrapper">
              <PersonalDetailsSection />
              <MfaMobileNumberSection />
            </Col>
            <Col md={6} lg={4} className="pl-0 pr-4 tfl-card-group-wrapper">
              <AddressDetailsSection />
              <OffersAndPromotionsSection />
            </Col>
            <Col md={6} lg={4} className="pl-0 pr-4 mb-3">
              <SecuritySection />
              <PersonalDataSection />
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={6} lg={4} className="pl-0 pr-4">
              <SignOutButton />
            </Col>
          </Row>
        </Col>
      </Container>
    </TfLAuthenticationTemplate>
  );
};
