import { useLogoutRedirect } from '../useLogoutRedirect';
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

// In the scenario where the B2C server session has expired but the client id token/refresh token is still valid it's
// possible for the user to be logged in to multiple customers. This happens when you edit a profile as the user will be
// prompted to login again and can use different credentials.
export const useEnsureSingleCustomer = () => {
  const { inProgress, accounts } = useMsal();
  const [logoutRedirect] = useLogoutRedirect();

  useEffect(() => {
    if (inProgress !== InteractionStatus.None) return;

    function onlyUnique(value: string, index: Number, array: string[]) {
      return array.indexOf(value) === index;
    }

    const logoutIfMultipleCustomers = async () => {
      if (accounts.length > 0) {
        const uniqueCustomerIds = accounts
          .map(
            (account) => (account?.idTokenClaims?.customerId as string) || ''
          )
          .filter(onlyUnique);

        if (uniqueCustomerIds.length > 1) {
          await logoutRedirect({
            postLogoutRedirectUri: '/my-account',
          });
        }
      }
    };

    logoutIfMultipleCustomers();
  }, [inProgress, accounts]);
};
