import { useEffect, useState } from 'react';
import createActivityDetector from 'activity-detector';

export function useIdleStatus(options: any) {
  const [isIdle, setIsIdle] = useState(false);
  useEffect(() => {
    if (!options.monitorActivity) {
      setIsIdle(false);
      return;
    }

    const activityDetector = createActivityDetector(options);

    activityDetector.on('idle', () => {
      setIsIdle(true);
    });
    activityDetector.on('active', () => {
      setIsIdle(false);
    });
    return () => {
      activityDetector.stop();
    };
  }, [options]);

  return isIdle;
}
