import {
  InteractionType,
  PopupRequest,
  RedirectRequest,
  SsoSilentRequest,
} from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { authRequest as auth } from '../../b2c';
import { ErrorComponent, LoadingComponent } from '.';

export interface AuthenticationRequest {
  children: React.ReactNode;
  authRequest?: PopupRequest | RedirectRequest | SsoSilentRequest;
}

const TfLAuthenticationTemplate = ({
  children,
  authRequest = auth,
}: AuthenticationRequest) => {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};

export default TfLAuthenticationTemplate;
