import { useEffect } from 'react';

// If the page is loaded from browsers back-forward cache and there is no user session present,
// then send user to login again by reloading the page, loginRedirect doesn't work 😭 on chrome.
// (this seems to be an issue on chrome only afaik, other major browsers load page properly on browser back)
// https://web.dev/bfcache/#update-stale-or-sensitive-data-after-bfcache-restore
// https://developer.mozilla.org/en-US/docs/Web/API/PageTransitionEvent/persisted
// https://docs.microsoft.com/en-us/azure/active-directory-b2c/cookie-definitions

const useHandleBrowserBack = () => {
  useEffect(() => {
    window.addEventListener('pageshow', (event) => {
      if (event.persisted && !document.cookie.match(/x-ms-cpim-/)) {
        window.location.reload();
      }
    });
  }, [window]);
};

export default useHandleBrowserBack;
