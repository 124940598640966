export const secrets = {
  idTokenHintSigningKey:
    process.env.REACT_APP_AZURE_B2C_ID_TOKEN_HINT_SIGNING_KEY,
};

export const jwtPayload = {
  protectedHeader: { alg: 'HS256', typ: 'JWT' },
  aud: process.env.REACT_APP_AZURE_B2C_AUDIENCE ?? '',
  iss: process.env.REACT_APP_AZURE_B2C_ISSUER ?? '',
  exp: '1d',
};
