import { TfLGoCardProps } from './TfLGoCard.types';
import classnames from 'classnames';
import './TfLGoCard.scss';

const TfLGoCard = ({
  appleBadge,
  androidBadge,
  header,
  description,
}: TfLGoCardProps) => {
  return (
    <div className="tfl-dashboard__GoCard">
      <div className="tfl-dashboard__GoCard-label-wrapper">
        <div className="tfl-dashboard__GoCard-header">{header}</div>
        <div className="tfl-dashboard__GoCard-description">{description}</div>
      </div>
      <div className="tfl-dashboard__GoCard-badge-wrapper">
        <a
          className={classnames([
            'tfl-dashboard__GoCard-appleBadge',
            `tfl-dashboard__GoCard-appleBadge--${appleBadge?.text}`,
          ])}
          href={appleBadge.url}
          aria-label={appleBadge.label}
        ></a>
        <a
          className={classnames([
            'tfl-dashboard__GoCard-androidBadge',
            `tfl-dashboard__GoCard-androidBadge--${androidBadge?.text}`,
          ])}
          href={androidBadge.url}
          aria-label={androidBadge.label}
        ></a>
      </div>
    </div>
  );
};

export default TfLGoCard;
