import { AddressDetailsCardProps } from './AddressDetailsCard.types';
import { Alert, Button, Loader } from '@tfl/component-library';
import classnames from 'classnames';
import './AddressDetailsCard.scss';

export const AddressDetailsCard = ({
  houseName,
  ukAddressLine,
  addressLine1,
  addressLine2,
  addressLine3,
  addressLine4,
  town,
  postcode,
  country,
  showLoader = false,
  showAlert = false,
  onClick,
}: AddressDetailsCardProps) => {
  return (
    <div
      className={classnames([
        'tfl-address-card',
        {
          'tfl-address-card--loading': showLoader,
        },
      ])}
    >
      {!showLoader ? (
        <>
          {!showAlert ? (
            <div>
              <div className="tfl-address-card__address">
                <div>{houseName}</div>
                <div>{ukAddressLine}</div>
                <div>{addressLine1}</div>
                <div>{addressLine2}</div>
                <div>{addressLine3}</div>
                <div>{addressLine4}</div>
                <div>{town}</div>
                <div>{postcode}</div>
                <div>{country}</div>
              </div>
            </div>
          ) : (
            <div className="tfl-address-card__alert">
              <Alert header={'Not available'} alertStyle={'error'} />
            </div>
          )}
          <div>
            <Button
              buttonStyle="secondary"
              value="Update address"
              onClick={onClick}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};
