import { Button } from '@tfl/component-library';
import './PersonalDataSection.scss';

export const PersonalDataSection = () => {
  const handleExternalLinkClick = (url: string) => () =>
    window.open(url, '_blank');

  return (
    <>
      <h2 className="tfl-personal-data-header">Privacy statement</h2>
      <div className="tfl-personal-data">
        <p>
          Transport for London (TfL), its subsidiaries and service providers
          will use your personal information for the purposes of customer
          services and administration, the provision of travel related
          information, customer research and fraud prevention. Your personal
          information will be properly safeguarded and processed in accordance
          with the requirements of privacy and data protection legislation.
        </p>{' '}
        <p>
          To find out more about how TfL handles personal data see our Privacy
          and cookies pages.
        </p>
        <div className="mb-3">
          <Button
            buttonStyle="icon-link-out"
            value="Privacy and cookies"
            onClick={handleExternalLinkClick(
              'https://tfl.gov.uk/corporate/privacy-and-cookies/'
            )}
            role="link"
          />
        </div>
        <div>
          <Button
            buttonStyle="icon-link-out"
            value="Your information rights"
            onClick={handleExternalLinkClick(
              'https://tfl.gov.uk/corporate/privacy-and-cookies/your-information-rights'
            )}
            role="link"
          />
        </div>
      </div>
    </>
  );
};
