import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { ProfileDetails } from '../api/api.types';

const CustomerProfile = createContext({
  state: {} as CustomerProfileState,
  setState: {} as Dispatch<SetStateAction<CustomerProfileState>>,
});

const CustomerProfileProvider = ({
  children,
  value = {} as CustomerProfileState,
}: {
  children: React.ReactNode;
  value?: CustomerProfileState;
}) => {
  const [state, setState] = useState<CustomerProfileState>(value);
  return (
    <CustomerProfile.Provider value={{ state, setState }}>
      {children}
    </CustomerProfile.Provider>
  );
};

const useCustomerProfileState = () => {
  const context = useContext(CustomerProfile);
  if (!context) {
    throw new Error(
      'useCustomerProfileState must be used within CustomerProfile page'
    );
  }
  return context;
};

const Debug = (data?: any) => {
  const { state } = useCustomerProfileState();
  return (
    <pre>
      {JSON.stringify(state || {}, null, 2)}
      <hr />
      {JSON.stringify(data || {}, null, 2)}
      <hr />
    </pre>
  );
};
export interface CustomerProfileState {
  profileDetails: ProfileDetails;
}

export { CustomerProfileProvider, Debug, useCustomerProfileState };
