import { OffersAndPromotionsCard } from '..';
import { getProfileEditRequest } from '../../b2c';
import { useCustomerProfileState } from '../../context/CustomerProfileContext';
import { useIdToken, useLoginRedirect } from '../../hooks';

export const OffersAndPromotionsSection = () => {
  const { getUpdateOffersPromotionsIdToken } = useIdToken();
  const [updateMarketingPreferences] = useLoginRedirect();
  const { state } = useCustomerProfileState();
  const { profileDetails } = state;
  const { tfLMarketingOptIn, tocMarketingOptIn } = {
    ...profileDetails,
  };

  const handleUpdateMarketingPreferences = async () => {
    const idToken = await getUpdateOffersPromotionsIdToken();
    await updateMarketingPreferences(getProfileEditRequest(idToken));
  };

  return (
    <>
      <h2 className="mb-3">Offers and promotions</h2>
      <OffersAndPromotionsCard
        tflOffers={tfLMarketingOptIn ? 'yes' : 'no'}
        tflDescription="I'd like to get offers and promotions from TfL and its subsidiaries"
        trainCompaniesOffers={tocMarketingOptIn ? 'yes' : 'no'}
        trainDescription="I'd like to get offers and promotions from train operating companies"
        showLoader={!profileDetails}
        showAlert={profileDetails && Object.keys(profileDetails).length === 0}
        onClick={handleUpdateMarketingPreferences}
      />
    </>
  );
};
