import { Button } from '@tfl/component-library';
import { MfaMobileNumberCardProps } from './MfaMobileNumberCard.types';
import './MfaMobileNumberCard.scss';

export const MfaMobileNumberCard = ({
  subHeader,
  description,
  buttonLabel,
  onClick,
}: MfaMobileNumberCardProps) => {
  return (
    <div className="tfl-authentication-card">
      <div>
        <span className="tfl-authentication-card__header">{subHeader}</span>
        <p className="tfl-authentication-card__text">{description}</p>
      </div>
      <div>
        <Button buttonStyle="secondary" onClick={onClick} value={buttonLabel} />
      </div>
    </div>
  );
};
