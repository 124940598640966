import { createServer, Registry, Server, Response } from 'miragejs';
import details from '../customerdetailsdata.json';
import { StartMirageFunctionProps } from './server.types';
import { AnyModels, AnyFactories } from 'miragejs/-types';
import jwt_decode from 'jwt-decode';
import { AccessToken } from '../../../b2c/TflIdTokenClaims.types';
import { ProfileDetails } from '../../api.types';

const { Customers: customers } = details;
const badResponseCustomerId = 112244;
const noDataCustomerId = 112255;

function getCustomerCredentialsFromRequestHeaders(
  requestHeaders: Record<string, string>
) {
  const accessToken = requestHeaders.authorization?.split(' ')[1];

  if (!accessToken) return null;

  try {
    const { customerId, customerAccountTier } = jwt_decode(
      accessToken
    ) as AccessToken & {
      customerId: number | null;
      customerAccountTier: string | null;
    };

    return { customerId, customerAccountTier } as ProfileDetails & {
      customerAccountTier: string | null;
    };
  } catch (error) {
    console.log(`🤷 invalid access token`);
    return null;
  }
}

const startMirage = ({
  environment = 'development',
  logging = false,
}: StartMirageFunctionProps = {}): Server<
  Registry<AnyModels, AnyFactories>
> => {
  return createServer({
    logging,
    environment,
    routes() {
      this.get(`${process.env.REACT_APP_DCR_CUSTOMER_URL}`, (_, request) => {
        const { customerId, customerAccountTier } =
          getCustomerCredentialsFromRequestHeaders(request.requestHeaders) ||
          {};

        if (customerId === badResponseCustomerId) return new Response(500);

        if (customerId === noDataCustomerId) return {};

        const customer = customers.find(
          (customer) => customer.CustomerId === customerId
        );

        return (
          customer ||
          customers?.find(
            (customer) =>
              customer.CustomerType.toLowerCase() ===
              customerAccountTier?.toLowerCase()
          ) ||
          customers[0]
        );
      }),
        this.passthrough(
          `${process.env.REACT_APP_AZURE_B2C_AUTHORITY_DOMAIN}/**`
        );
      this.passthrough('https://consentcdn.cookiebot.com/**');
    },
  });
};

export default startMirage;
