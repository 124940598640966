import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import React from 'react';
import { useIdToken } from '..';
import { ErrorCodes, policies } from '../../b2c';
import { useHandleB2CErrors } from '..';
import { useLocation } from 'react-router-dom';

const locationsRequiringAuth = ['/', '/profile', '/sign-in'];
const signOutPath = '/sign-out';

export const useSyncSessionWithServer = () => {
  const location = useLocation();
  const [currentError] = useHandleB2CErrors();
  const { FORGOT_PASSWORD } = ErrorCodes;
  const { instance, inProgress } = useMsal();
  const { getSigninSignupIdTokenWithMfa } = useIdToken();
  const [serverSessionSynced, setServerSessionSynced] = React.useState(false);

  useEffect(() => {
    if (inProgress !== InteractionStatus.None) return;

    if (currentError && currentError?.includes(FORGOT_PASSWORD)) return;

    if (location.pathname === signOutPath) {
      setServerSessionSynced(true);
      return;
    }

    if (serverSessionSynced) return;

    const getTokenSilent = async () => {
      const idTokenHint = await getSigninSignupIdTokenWithMfa();
      const authRequest = {
        ...policies.authorities.signUpSignIn,
        extraQueryParameters: { id_token_hint: idTokenHint },
      };

      try {
        //Perform an ssoSilent request to get the session that exists on the auth server (b2c).
        //This is to ensure that we know which user is signed in to the server and only sign the user in if there is an active server session.
        await instance.ssoSilent(authRequest);
        setServerSessionSynced(true);
      } catch (error) {
        //If the ssoSilent request fails we should log the user out locally and invoke the sign in policy to prompt for credentials.
        const allAccounts = instance.getAllAccounts();
        for (const account of allAccounts) {
          await instance.logoutRedirect({
            account,
            onRedirectNavigate: () => false,
          });
        }

        setServerSessionSynced(true);
      }
    };

    getTokenSilent();
  }, [instance, inProgress]);

  return [serverSessionSynced];
};
