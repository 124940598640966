import { PropsWithChildren, useEffect } from 'react';

const ScrollToTop: React.FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <>{children}</>;
};

export default ScrollToTop;
