import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { configuration } from './b2c';
import App from './App';
import { startMirage } from './api/mock/server';
import { AuthContextProvider } from './context';

export const pca = new PublicClientApplication(configuration);

if (process.env.REACT_APP_USE_MIRAGE === 'true') {
  startMirage();
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <MsalProvider instance={pca}>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </BrowserRouter>
  </MsalProvider>
);
